import React, { FunctionComponent } from 'react';
import './index.scss';
import { PageProps, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Welcome } from '../components/Welcome';
import Posts from '../components/Posts';
import { Projects } from '../components/Projects';
import About from '../components/About';

export const inBrowser = typeof window !== 'undefined';

const GoHome = () => (
  <a href="/" className="animated">
    &laquo; home
  </a>
);

export const Layout: FunctionComponent = ({ children }) => {
  return (
    <div className="App container py-4">
      {inBrowser && document.location.pathname !== '/' && <GoHome />}
      {children}
      <About />
    </div>
  );
};

const HomePage = ({ data }: PageProps<Data>) => {
  const posts = data.allMarkdownRemark.edges.map(({ node }) => {
    return {
      title: node.frontmatter.title || node.fields.slug,
      url: node.fields.slug,
      date: node.frontmatter.date,
      description: node.frontmatter.description,
      tags: node.frontmatter.tags
    };
  });

  return (
    <Layout>
      <Helmet>
        <title>Dilraj</title>
      </Helmet>

      <Welcome />
      <Posts posts={posts} />
      <Projects />
    </Layout>
  );
};

export default HomePage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { publish: { eq: "yes" } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            title
            description
            publish
            tags
          }
        }
      }
    }
  }
`;

type Data = {
  allMarkdownRemark: {
    edges: {
      node: {
        excerpt: string;
        frontmatter: {
          title: string;
          date: string;
          description?: string;
          tags?: string;
          publish?: String;
          coverImage?: String;
        };
        fields: {
          slug: string;
        };
      };
    }[];
  };
};
