import React from 'react';
import { Card, CardBody } from 'reactstrap';

function About() {
  return (
    <Card className="mt-3 about-image">
      <CardBody className="d-flex">
        <div className="jumbo-text p-3 h-100 ml-auto about-text rounded">
          <div className=" p-1 pb-3">
            <img
              src="/profile-thumb.jpeg"
              alt="Dilraj Singh profile"
              className="rounded-circle float-left mr-2 d-md-none"
              width="70w"
            />
            <span className="display-4 d-sm-none">Dilraj S.</span>
            <span className="display-4 d-none d-sm-inline-block">
              Dilraj Singh
            </span>
          </div>
          <p>
            I <mark>code</mark> and do some other things, like practice writing.
          </p>
          <p>
            <span role="img" aria-label="hobbies">
              👨🏽‍💻⛰⚽️
            </span>
          </p>
          <p>
            <a className="animated" href="https://github.com/raj-al-ghul">
              Github
            </a>
            <span className="mx-1 mx-md-3">&middot;</span>
            <a className="animated" href="https://www.linkedin.com/in/dilraj7">
              LinkedIn
            </a>
            <span className="mx-1 mx-md-3">&middot;</span>
            <a className="animated" href="https://twitter.com/dilraj_singh">
              @dilraj_singh
            </a>
          </p>
        </div>
      </CardBody>
    </Card>
  );
}

export default About;
