import React from 'react';
import { Jumbotron } from 'reactstrap';

export function Welcome() {
  const arr = [
    // 'https://source.unsplash.com/_dwwHrYRWcM/1200x600',
    'https://source.unsplash.com/Ci45QAMjzpY/1200x600'
  ];

  return (
    <Jumbotron
      className="jumbo-image pb-3"
      style={{
        backgroundImage: `url(${arr[Math.floor(Math.random() * arr.length)]})`
      }}
    >
      <div className="my-5">&nbsp;</div>
      <span className="display-4 mt-5 jumbo-text">
        hi{' '}
        <span className="wave" role="img" aria-label="wave">
          👋
        </span>
      </span>
      <br />
      <span className="display-4 jumbo-text" style={{ fontSize: '2.3em' }}>
        welcome to my little corner of the internet.
      </span>
    </Jumbotron>
  );
}
