import React, { useEffect, useState } from 'react';
import { CardBody, CardDeck, CardSubtitle, CardTitle } from 'reactstrap';
import { GithubStarredItem, GithubStarredItems } from './GithubStarredApiTypes';

export function Projects() {
  /**
   * wanted to do :
   *    - my public pinned repos
   *    - but this is only in the [Authenticated] graphql api
   *
   * Next best thing:
   *  - starred repos that where am the owner
   */

  const [projectsFromApi, setProjectsFromApi] = useState<GithubStarredItems>(
    []
  );

  useEffect(() => {
    async function getProjectsFromGithub() {
      const response = await fetch(
        'https://api.github.com/users/raj-al-ghul/starred'
      );
      const data: GithubStarredItems = await response.json();

      // TODO sort by date?
      const projsToDisplay = data.filter(
        p =>
          !p.private && ['OneiricArts', 'raj-al-ghul'].includes(p.owner.login)
      );

      setProjectsFromApi(projsToDisplay);
    }

    getProjectsFromGithub();
  }, []);

  return (
    <>
      <h1 className="display-4">Projects</h1>
      <CardDeck>
        {projectsFromApi.map(p => (
          <ProjectCard project={p} key={p.id} />
        ))}
      </CardDeck>
    </>
  );
}

function ProjectCard({ project }: { project: GithubStarredItem }) {
  return (
    <a href={project.html_url} className="card mb-4 my-card">
      <CardBody className="shadow d-flex flex-column">
        <CardTitle>
          <h5>{project.name}</h5>
          {/* {project.owner.login} */}
        </CardTitle>

        <p className="small text-muted">{project.description}</p>

        {project.homepage && (
          <p className="small text-muted">
            deployed at:{' '}
            <a href={project.homepage} className="animated">
              {project.homepage}
            </a>
          </p>
        )}

        <div className="small text-muted mt-auto">
          Created: {new Date(project.created_at).toLocaleDateString()}
        </div>
      </CardBody>
    </a>
  );
}
