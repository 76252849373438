import React from 'react';
import { CardTitle, CardSubtitle, CardBody, CardDeck, Badge } from 'reactstrap';
import { Link } from 'gatsby';

interface Post {
  url: string;
  title: string;
  date: string;
  tags?: string;
  coverImage?: string;
}

export default function Posts({ posts }: { posts: Post[] }) {
  return (
    <>
      <h1 className="display-4">Blog</h1>
      <CardDeck>
        {posts.map(p => (
          <PostCard key={p.url} post={p} />
        ))}
      </CardDeck>
    </>
  );
}

function PostCard({ post }: { post: Post }) {
  const { date, title, url, tags } = post;

  const dateToDisplay = new Date(date).toDateString();
  return (
    <Link to={url} className="card mb-4 my-card">
      <CardBody className="shadow">
        <CardTitle>
          <h5 dangerouslySetInnerHTML={{ __html: title }} />
        </CardTitle>
        <CardSubtitle>
          <i
            className="small text-muted"
            dangerouslySetInnerHTML={{ __html: dateToDisplay }}
          />
        </CardSubtitle>
        {tags
          ?.split(',')
          .map(e => e.trim())
          .map(t => (
            <Badge key={t} color="light" className="mr-1">
              {t}
            </Badge>
          ))}
      </CardBody>
    </Link>
  );
}
